<script>
export default {
  mounted() {
  }
}
</script>

<template>

<section>
Здравейте читатели!
След дълго време прекарано във работа по съдържанито и дизайна на сайта най-после можем да кажем, че първата версия е готова. Можете да се насладите на интересни новини, данни за произход и бързи срещи във сайта на Копанарите.

От страницата за Произход можете да прочетете за генетични тестове, исторически документи и неща, кооито се знаят за движението на Копанарите. Ще се радваме да научим повече, така че приемаме повече данни и редакции. Възползвайте се от контактната форма и ни изпратете съобщение свързано със сигурни данни за произхода, за да обогатим сайта.

От Блогът, страницата която посещавате в момента, можете да научите най-новите новини в Копанарския сайт, както и полезни публикации свързани със културата и традициите им.

лъжици
Най-интересна е страницата за Срещи. От там можете да се регистрирате за среща и да се запознаете със други копанари и копанарки от противоположния пол. След края на срещата ако се харесате взаимно ще получите контактие на отсрещния човек.

От Магазина можете да купите различни атрибути като Тениски, Чаши, Дървени лъжици и още. Доставяме до България без проблем със Стандартна доставка. За чужбина доставката ще е по-трудна, но ще измислим нещо нормално.

Надяваме се да ви хареса да имаме общо пространство, което да обогатяваме, в което да можем да се запознаваме със други хора от своя вид.

Поздрави,
Екипът на Копанари



</section>
</template>
