<script>
export default {
  mounted() {
  }
}
</script>

<template>

  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center justify-content-between">
      <h1 class="logo"><a href="index.html">Копанари</a></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo"><img src="/src/assets/img/logo.png" alt="" class="img-fluid"></a>-->
      <nav id="navbar" class="navbar" style="display: block;">
        <ul>
          <li><router-link class="nav-link scrollto active" to="/">Начало</router-link></li>
          <li><router-link class="nav-link scrollto" to="/ancestry">Произход</router-link></li>
          <li><router-link class="nav-link scrollto" to="/news">Новини</router-link></li>
          <li><router-link class="nav-link scrollto " to="/contacts">Контакти</router-link></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->
    </div>
  </header><!-- End Header -->
</template>
