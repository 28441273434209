<script>
export default {
  mounted() {
  }
}
</script>

<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container d-flex flex-column align-items-center justify-content-center" data-aos="fade-up">
      <h1>Обединени Копанари</h1>
      <h2>Официалния сайт на копанарската общност и копанарския етнос в България и зад граница</h2>
      <a href="#about" class="btn-get-started scrollto">Произход</a>
      <img src="/src/assets/img/hero-img.png" class="img-fluid hero-img" alt="" data-aos="zoom-in" data-aos-delay="150">
    </div>

  </section><!-- End Hero -->

</template>
