<script>
export default {
  mounted() {
  }
}
</script>

<template>

  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Връзки</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Начало</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Новини</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Контакти</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Относно</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Произход</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Групи</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Запишете се за Бюлетин</h4>
            <p>Можете да получавате всички новини по имейл. Само напишете имейла си във формата.</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Запиши ме">
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">

      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; Copyright <strong><span>Такерман</span></strong>. Всички права запазени
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bikin-free-simple-landing-page-template/ -->
        </div>
      </div>
      <div class="social-links text-center text-md-right pt-3 pt-md-0">
      </div>
    </div>
  </footer><!-- End Footer -->

</template>
