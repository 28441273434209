import Home from "@/views/Home.vue";
import { createRouter, createWebHistory } from "vue-router";
import Ancestry from "@/components/Ancestry.vue";
import News from "@/components/News.vue";
import Contact from "@/components/Contact.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/ancestry",
      name: "ancestry",
      component: Ancestry,
    },
    {
      path: "/news",
      name: "news",
      component: News,
    },
    {
      path: "/contacts",
      name: "contacts",
      component: Contact,
    }
  ]
});

export default router;
