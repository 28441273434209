<script>
export default {
  data() {
    return {
      loading: false,
      statusClass: 'sent-message',
      status: null
    }
  },
  methods: {
    async sendMessage() {
      this.loading = true;
      const message = {
        Subject: document.getElementById('subject').value,
        From: document.getElementById('email').value,
        Body: document.getElementById('name').value + ' sent you a message: ' + document.getElementById('message').value,
        To: 'tivanov@takerman.net'
      }
      await fetch('home/sendMessage', {
        method: 'POST',
        headers: {
          Accept: 'application.json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(message),
        cache: 'default'
      }).then((result) => {
        debugger;
        this.loading = false;
        this.statusClass = "sent-message";
        this.status = 'Your message has been sent. Thank you!';
      }).catch((error) => {
        debugger;
        this.loading = false;
        this.statusClass = "error-message";
        this.status = 'There was an error while sending the message! Apologies. Please use the email.';
      });
    }
  },
}
</script>
<template>

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact section-bg">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>Контакти</h2>
        <p>Можете да се свържете с нас чрез контактната форма долу. Изпратете ни съобщение и ние ще ви върнем отговор на посочения имейл. Ако имате проблем, запитване или предложения ще се радваме, да ги чуем.</p>
      </div>

      <div class="row">

        <div class="col-lg-6">

          <div class="row">
            <div class="col-md-6">
              <div class="info-box mt-4">
                <i class="bx bx-envelope"></i>
                <h3>Имейл</h3>
                <p><a href="mailto:contact@kopanari.net">contact@kopanari.net</a></p>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6 mt-4 mt-md-0">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Име" required>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Имейл" required>
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Заглавие" required>
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="Съобщение" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Зареждане</div>
              <div class="error-message"></div>
              <div class="sent-message">Съобщението ви беше изпратено. Благодаря!</div>
            </div>
            <div class="text-center"><button type="submit">Изпрати</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->

</template>
