<script>
export default {
  mounted() {
  }
}
</script>

<template>

<section>
Движението на племето е започнало като повечето балкански народи със разликата, че те са преминали под Черно море докато повечето са преминали отгоре/над Черно море. Започнали са движението си от Югоизточен Иран и са преминали на Запад към Техеран и Османската Империя. От там са били транспортирани близо до Измир и са се задържали там. Преминали са към Кипър, Гърция и след това транспортирани в частите на Румъния близо до Унгария. През това време светът е оперирал в режим на Империи, градящи се на робство и са работели като роби в Мините. От там произлиза и названието им Рудари, което означава, че са работели в Рудниците.

Преди около 150 години, след различни войни и конфликти, светът е решил, че империи не трябва да се градят на робство и са го забранили. Империите са се разпределили в Държави и вече Копанарите били свободни. Тогава са се разделили на 3 течения. Едното заминало за България, друго заминало за Америка, тъй като тогава се е открила тя а последното е останало на мястото си някъде там покрай Унгария и Румъния.

В днешно време поради обединението на Европа, копанарите са се пръснали в различни държави и са поели своя път. Задачата ни е да се почувстват по-обединени и свързани.

Тези неща са доказани чрез множество исторически проекти, а от скоро и чрез генетиката. Чрез изследването на Гените могат да се проследят движенията на народите. Във тях има силно Иранско присъствие, както и част Европейско. Пример за такова разпределение може да се види по-долу. Гените им са доста разнообразни:

Естествено има различни композиции, като този е силно подействан от Иранските гени. Има и хора със повече Европейски такива. Можете да направите такъв тест върху вас, за да разберете. Тестове като 23andMe, My Heritage и тн. Струват около 110лв.

Битът на копанарите е бил свързан със изработката на лъжици, купи и други подобия от дърво и продаването им по пазарите. Някой от тях също са се занимавали със дресиране на животни. В днешно време вече нещата стоят по съвсем различен начин. Всеки е поел своя път и традициите започват да остават някъде там назад във времето.

Има доста интересни документи, които сочат, че наистина във Унгария, България, Гърция и Америка има най-много Копанари. Можете да свалите този документ и да прочетете какво пише в него.

Това е интерактивна карта, която показва, къде са населените територии със Рудари в Европа и тук можете да прочетете повече от течението им по Балканите.

Всички тези ресурси все още се до уточняват, тъй като ситуацията постоянно се променя. Но се оказва, че има доста Копанари и са специфичен набор от хора със специфичен език и няколко интересни обичая.

Бележка: За редакции или добавяне на още неща в публикацията, моля пишете ни от страницата за Контакти.

Полезни връзки
Обичай на власите във Унгария. Около 100 хиляди души. (английски)
Академични ресурси за Бояш (английски)

</section>
</template>
